@charset "utf-8";

$base-font-family: 'Roboto', serif;
$base-font-size:   1.025em;
$small-font-size:  0.875em;
$vsmall-font-size: 0.75em;
$base-line-height: 1.5;
$spacing-unit:     0.5em;

$grey-vlight:#eee;
$grey-light: #ccc;
$grey:       #888;
$grey-dark:  #444;
$grey-vdark: #222;
$blue:       #0091ed;
$orange:     #ca6c18;
$red:        #b6321c;

$background:        #E5E5E5;
$dark-background:   darken($background, 5%);
$text-color:        #46433A;
$text-light-color:  $text-color;
$url-color:         #006633;
$url-hover-color:   $orange;
$header-text-color: $text-color;
$header-hover-color:$text-light-color;
$header-hover-url:  $url-color;
$line-color:        $grey-light;

// Width of the content area
$content-width:    60em;
$content-min-width:20em;
$mobile:           42em;
$desktop:          600em;

.imgContainer{
    float:left;
    margin-right: 50px;
}

h1 {
    font-family: 'Open Sans', sans-serif;
}

h2 {
    font-family: 'Open Sans', sans-serif;
}

.sub-menu {
    background: $background;
}

.post {
    padding: 12px;
}

@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import
    "header",
    "mobile-header",
    "base",
    "footer",
    "page",
    "syntax-highlighting",
    "fancy-image"
;


.testImages {
  display: table;
  width: 95%;
  left: auto;
  right: auto;
}

.inputImage {
  display: table-cell;
  padding: 10px;
  width: 30%;
  vertical-align: middle;
  text-align: center;
}

.newImage {
  height: 200px;
  width: auto;
}

.memberProfile {
  display: table;
}

.memberProfile-left {
  display: table-cell;
  width: 70%;
  vertical-align: top;
}

.memberProfile-right {
  display: table-cell;
  width: 30%;
  overflow: hidden;
  align: right;
  text-align: center;
}

ul {
  padding-left: 40px;
}
